<template>
  <container ref="container" class="joinUs" @error="init">

    <!-- 新闻 -->
    <div class="news">
      <!-- 新闻-列表 -->
      <div class="news-list">
        <!-- 新闻-元素 -->
        <news-card :detail="item" v-for="item in news.list" :key="item.id" />
      </div>

      <!-- 分页 -->
      <pagination :total="news.total" :current="news.page" @change="getData" />
    </div>
  </container>
</template>

<script>
import pagination from "@/components/pagination.vue";
import newsCard from "@/components/news-card.vue";

export default {
  name: "joinUs-news",
  components: {
    pagination,
    newsCard,
  },
  data() {
    return {
      breadcrumb: {
        router: ["首页", "招商加盟"],
      },
      news: {
        list: [],
        page: 1,
        total: 1,
      },
      video: {
        list: [],
      },
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      // 获取列表
      this.getData(1);
    },

    /**
     * 获取列表
     */
    async getData(page) {
      try {
        // 设置页号
        this.news.page = page || this.news.page;

        // 设置状态
        this.$refs.container.status("init", {
          height: "50vh",
        });

        const [news] = await Promise.all([
          this.$axios("/api/mainstation/joinDynamic", {
            params: {
              type: 1,
              num: 5,
              page: this.news.page,
            },
          }),
        ]);

        // 设置数据
        this.news.list = news.data.newslist;
        this.news.total = news.data.pagenum;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "50vh",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 企业
  .news {
    width: 1200px;
    margin: auto;

    // 企业-列表
    &-list {
      padding: 40px 0;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 企业
  .news {
    width: 100%;
    margin: auto;

    // 企业-列表
    &-list {
      padding: rpx(40) 0;
    }
  }
}
</style>
